<template>
    <div>
        <div class="container">
            <Head></Head>
        </div>
        <div class="container content">
            <MainMenu ref="MainMenu"></MainMenu>
            <div class="Persons Inner">
                <div class="leftMenu">
                    <el-menu :default-active="activeIndex"
                             mode="vertical"
                             @select="handleSelect">
                        <el-menu-item index="alphaList">{{ $t('label.alphaList') }}</el-menu-item>
                        <el-menu-item :index="item.id" v-for="(item,i) in categories" :key="i"
                                      v-html="(locale === 'ru' ? item.pagetitle : item.longtitle)"></el-menu-item>
                    </el-menu>
                </div>
                <div class="wrap" ref="wrap">
                    <div class="pageTitle">
                        <h1>
                            <div>
                                <template v-if="curIndex === 'alphaList'">
                                    {{$t('label.alphaList')}}
                                </template>
                                <template v-else>
                                    {{pageTitle}}
                                </template>
                                <span v-if="alphaData.total && curIndex === 'alphaList'"
                                      class="label">{{alphaData.total}}</span>
                                <span v-else-if="!load.total && curIndex !== 'alphaList'"
                                      class="label">{{total.list}}</span>
                                <span v-else class="label"><i class="fas fa-spinner fa-pulse"></i></span>
                            </div>
                            <div class="predicateSearch" v-if="curIndex !== 'alphaList'">
                                <el-input v-model="filter.predicate"
                                          clearable
                                          @change="handleSearch">
                                    <el-button slot="append"
                                               @change="handleSearch">
                                        <i class="fal fa-search"></i>
                                    </el-button>
                                </el-input>
                            </div>
                            <div>
                                <el-button :type="show.needModerationOnly ? 'primary' : 'default'"
                                           v-show="isAdmin && curIndex === 'alphaList'"
                                           @click="show.needModerationOnly = !show.needModerationOnly"
                                           title="Показать только требующие модерацию">
                                    <i class="fad fa-eye-slash"></i>
                                </el-button>
                                <el-button type="primary" plain
                                           @click="handleAddPerson"
                                           :title="$t('label.addPerson')">
                                    <i class="fas fa-user"></i>
                                    <i class="far fa-plus"></i>
                                </el-button>
                            </div>
                        </h1>
                    </div>
                    <div class="list-container" v-if="curIndex === 'alphaList'"
                         ref="alphaListContainer"
                         v-loading="alphaData.loading"
                         element-loading-background="rgba(255, 255, 255, 0.5)">
                        <div class="alpha_section"
                             :class="{'showNeedModerationOnly': show.needModerationOnly}"
                             v-for="(item, i) in alphaData.list" :key="`letter-${i}`">
                            <h4 v-show="!show.needModerationOnly">{{item.letter}}</h4>
                            <div class="alpha_items">
                                <a v-for="prs in item.persons" :key="prs.prs_id"
                                   v-if="!show.needModerationOnly && (isAdmin || prs.prs_pubstat !== 'need moderation')
                                              ||  (show.needModerationOnly && prs.prs_pubstat === 'need moderation')"
                                   :href="`javascript:void(${prs.prs_id})`"
                                   @click="handleShowPerson(prs)"
                                   :title="prs.prs_profstat">
                                    <template v-if="locale === 'ru'">
                                        {{prs.prs_F}} {{prs.prs_I}} {{prs.prs_O}}
                                    </template>
                                    <template v-else>
                                        {{prs.prs_F_en}} {{prs.prs_I_en}} {{prs.prs_O_en}}
                                    </template>
                                    <span v-if="isAdmin && prs.prs_pubstat === 'need moderation'">
                                        <i class="fad fa-eye-slash small ms-2"
                                           title="Неопубликован. Требуется модерация"></i>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="prsList list-container" v-else
                         ref="listContainer"
                         v-loading="load.prsList">
                        <PersonList :prsList="prsList"
                                    :filter="filter"
                                    @handleShowPerson="handleShowPerson"
                                    @editPerson="handleEditPerson"
                                    @personDeleted="handleDeletedPerson"/>

                        <el-pagination
                                small
                                background
                                :hide-on-single-page="true"
                                layout="prev, pager, next, jumper"
                                :total="total.list"
                                :page-size=20
                                :current-page.sync="page"
                                @current-change="onChangePage"/>
                    </div>
                </div>
            </div>
        </div>
        <el-backtop target=".wrap" :bottom="20" :right="20"></el-backtop>
    </div>
</template>

<script>
    import PersonList from "../components/PersonsList";
    import MainMenu from "../components/MainMenu";
    import Head from "../components/Head";

    export default {
        name: 'Persons',
        components: {
            Head,
            PersonList, MainMenu
        },
        props: {},
        data() {
            return {
                activeIndex: "alphaList",
                curIndex: 'alphaList',
                pageTitle: '',
                prsList: [],
                personToShow: {},
                personToEdit: {},
                page: 1,
                filter: {
                    predicate: ''
                },
                show: {
                    personCard: false,
                    needModerationOnly: false,
                },
                total: {
                    categories: 0,
                    list: 0
                },
                load: {
                    prsList: true,
                    total: true
                },
            }
        },
        mounted() {
            this.$refs.MainMenu.updateCurPage('persons');
            this.$store.dispatch('getAlphaData', this.locale);
            if (this.$route.query && this.$route.query.prs_id) {
                let prs = {
                    prs_id: this.$route.query.prs_id
                }
                this.handleShowPerson(prs);
            }

        },
        computed: {
            locale() {
                return this.$store.state.locale;
            },
            isAdmin() {
                return this.$store.getters.isAdmin;
            },
            isEn() {
                return this.$store.getters.locale === 'en'
            },
            categories() {
                return _.orderBy(this.$store.getters.initData.categories, (this.isEn ? "longtitle" : "pagetitle"), 'asc');
            },
            alphaData() {
                return this.$store.getters.alphaData;
            },

        },
        watch: {
            locale(newVal) {
                let self = this;
                self.$nextTick(() => {
                    self.handleSelect(self.curIndex);
                    self.changePageTitle(newVal);
                });
                self.$store.dispatch('getAlphaData', this.locale);
            },
            alphaData() {
                if (this.curIndex !== 'alphaList') {
                    this.handleSearch();
                }
            }
        },
        methods: {
            refreshPersonList(category) {
                if (parseInt(category)) {
                    let self = this,
                        query = {
                            data: {
                                mod: 'show_person_list',
                                category: category,
                                locale: self.locale,
                                text: self.filter.predicate,
                                page: self.page
                            }
                        };
                    self.load.prsList = self.load.total = true;
                    self.prsList = [];

                    self.$req(query).then((resp, textStatus, request) => {
                        self.prsList = resp.prs;
                        self.load.prsList = self.load.total = false;
                        self.total.list = resp.total;
                        self.$refs.listContainer.scrollTo({
                            top: 0,
                            behavior: "smooth"
                        });
                        // console.log('request', request.getResponseMainMenuer('content-type'),' | textStatus:', textStatus);
                    })
                }
            },
            handleSearch() {
                this.page = 1;
                this.refreshPersonList(this.curIndex)
            },
            handleShowPerson(prs) {
                this.personToShow = prs;
                this.$nextTick(() => {
                    this.$store.commit("showPersonCard", prs);
                    // this.$refs.personCard.refresh();
                });
            },
            handleAddPerson() {
                let typeId = this.curIndex !== 'alphaList' ? this.curIndex : null;
                let  prs = typeId ? {prs_type_id: typeId} : {};
                this.handleEditPerson(prs)
            },
            handleShowNeedModeration() {
                let self = this;
                self.show.needModerationOnly = !self.show.needModerationOnly;
            },
            handleEditPerson(prs) {
                this.personToEdit = prs;
                this.$nextTick(() => {
                    this.$store.commit("editPerson", prs);
                });
            },
            handleUpdatePerson(prs_id) {
                let self = this;
                if (prs_id && self.personToShow.prs_id === prs_id) {
                    this.$store.commit("personCard", {refresh: true});
                }
                self.refreshPersonList(self.curIndex);
            },
            handleDeletedPerson(prs_id) {
                let self = this;
                self.refreshPersonList(self.curIndex);
            },
            handleSelect(index) {
                let self = this;
                self.curIndex = index;
                self.page = 1;
                if (index !== 'alphaList') {
                    self.changePageTitle(self.locale);
                    self.refreshPersonList(index);
                }
                self.$refs.wrap.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
            },
            changePageTitle(locale) {
                let self = this,
                    curCategory = self.categories.find(it => it.id === self.curIndex) || {};
                self.pageTitle = locale === 'ru' ? curCategory.pagetitle : curCategory.longtitle;

            },
            onChangePage(page) {
                this.page = page;
                this.refreshPersonList(this.curIndex);
            },

        },
    }
</script>

<style lang="scss">
    @import "../assets/variables";

    .Persons {
        .alpha_section {
            margin: 0 0 30px;
            border-bottom: 1px solid hsla(41, 18%, 24%, .65);
            padding-bottom: 22px;

            .alpha_items {
                columns: 2;

                a {
                    display: block;
                    margin: 0 0 6px 0
                }
            }

            &.showNeedModerationOnly {
                margin: 0;
                padding: 0;
                border: none;
            }
        }
    }

</style>